import * as React from 'react';
import { Link } from 'gatsby';

import Page from 'components/Page';
import Container from 'components/Container';

const Page2: React.FC<void> = () => (
  <Page>
    <Container>
      Contact
    </Container>
  </Page>
);

export default Page2;
